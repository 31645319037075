import type { OrderPluginDto } from '../types';
import { Order } from '../entities';
import { deepClone } from '@/utils/helpers/deepClone';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { storagePlugin } from '@/features/core/storage';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import type { EntityType } from '@/features/core/entity-repository';
import { EntityUpdatedEvent } from '@/features/core/entity-repository';
import type { EntityUpdate } from '@/features/service-worker';
import { loggerServicePlugin } from '@/features/core/logger';

export class SaveOrderToStoragePlugin<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  public async execute(dataTransferObject: T): Promise<T> {
    let cloneOrder: Order;
    try {
      cloneOrder = deepClone(dataTransferObject.order);
    } catch (e) {
      loggerServicePlugin.get().error(e as Error, dataTransferObject.order);

      throw e;
    }

    await storagePlugin.get().save(Order.from(cloneOrder));
    const entityUpdateData: EntityUpdate<EntityType<Order>>[] = [
      {
        entity: Order,
        ids: [dataTransferObject.order.id],
        updated: true,
      },
    ];

    eventBusServicePlugin.get().emit(new EntityUpdatedEvent(entityUpdateData));

    return dataTransferObject;
  }
}
