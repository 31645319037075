import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { LoggerService } from '@/features/core/logger';
import type { Order } from '@/features/orders';
import type { OrderPluginDto } from '../types';

/**
 * Log order related action with order template variable like e.g.: 'Some action happened with Order {{id}} with {{localStatus}} local status.'
 **/
export class LogOrderPlugin<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  constructor(
    private loggerService: LoggerService,
    private logMessage: string,
    private logLevel: 'debug' | 'error' | 'info' = 'debug',
  ) {}

  public execute(dataTransferObject: T): T {
    const formattedMessage = this.formatMessage(dataTransferObject.order);

    this.loggerService[this.logLevel](formattedMessage, {
      order: dataTransferObject.order,
    });

    return dataTransferObject;
  }

  private formatMessage(order: Order): string {
    return this.logMessage.replace(/{{(\w+)}}/g, (match, property) => {
      if (property in order) {
        const value = order[property as keyof Order];
        return value != null ? value.toString() : 'undefined';
      }

      return 'undefined';
    });
  }
}
