import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import { Logs, LogsStorageSetup } from './logs';

// TODO: Export only the stuff which is allowed to be used from outside of this feature
export * from './logs';

export const logsEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin.get().add(Logs, new LogsStorageSetup());
});
