import type { Product } from '@/features/products/entities';
import type { OrderItem } from '@/features/orders/types';
import { isItemEntity } from './isItemEntity';

export const getNormalizedWeightUnit = (
  concreteItem: OrderItem | Product,
): string => {
  const unit = isItemEntity(concreteItem)
    ? concreteItem.product.unit
    : concreteItem.unit;
  return unit.toLowerCase();
};
