import type { PipelinePlugin } from '@ads/plugin-pipeline';
import {
  OrderLocalStatus,
  OrderEventNames,
  ordersServicePlugin,
} from '@/features/orders';
import type { ConfigTracking, OrderPluginDto } from '../types';

export class TrackOrderEventByLocalStatusPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  private configTracking: ConfigTracking = {
    [OrderLocalStatus.PickingReady]: {
      name: OrderEventNames.picking_started,
      options: {
        skipSaving: true,
      },
    },
    [OrderLocalStatus.PickingCompleted]: {
      name: OrderEventNames.picking_completed,
      options: {},
    },
    [OrderLocalStatus.PickingCancelled]: {
      name: OrderEventNames.staging_started,
      options: {},
    },
    [OrderLocalStatus.HandoverCancelled]: {
      name: OrderEventNames.pickup_canceled,
      options: {},
    },
    [OrderLocalStatus.HandoverInProgress]: {
      name: OrderEventNames.staging_completed,
      options: {},
    },
    [OrderLocalStatus.HandoverReady]: {
      name: OrderEventNames.bags_picked,
      options: {
        skipSaving: true,
      },
    },
    [OrderLocalStatus.Paused]: {
      name: OrderEventNames.pausing_completed,
      options: {
        allowMultipleTracking: true,
      },
    },
    [OrderLocalStatus.PickingInProgress]: {
      name: OrderEventNames.picking_resumed,
      options: {
        allowMultipleTracking: true,
      },
    },
  };
  public async execute(
    dataTransferObject: OrderPluginDto,
  ): Promise<OrderPluginDto> {
    const currentEvent =
      this.configTracking[dataTransferObject.order.localStatus];
    if (dataTransferObject.targetRoute?.name === 'bags-collection') {
      currentEvent.name = OrderEventNames.pickup_started;
    }
    dataTransferObject.order = await ordersServicePlugin
      .get()
      .trackEvent(
        dataTransferObject.order,
        currentEvent.name,
        currentEvent.options,
      );
    return dataTransferObject;
  }
}
