import type { ComponentPublicInstance, ComputedRef } from 'vue';

export const APP_CRASH_ERROR_PREFIX = 'APP-Crash: ';

export interface UseAppServiceResolver {
  readonly ready: ComputedRef<boolean>;
  readonly error: ComputedRef<null | Error>;
  readonly instance: ComputedRef<null | ComponentPublicInstance>;
  readonly info: ComputedRef<null | string>;
  handleCapturedError: (
    e: Error,
    instance: ComponentPublicInstance | null,
    info: string,
  ) => boolean;
}

export interface Resolver {
  resolve(): void;
  reject(reason?: unknown): void;
}
