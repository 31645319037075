import { eventBusServicePlugin } from '@/features/core/event-bus';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderEventBusEventsMap } from '../types';
import type { OrderPluginDto } from '../types';

/**
 * Emits event bus event after status changed
 **/
export class EmitEventBusStatusChangePlugin<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  constructor(private action: keyof typeof OrderEventBusEventsMap) {}

  public execute(dataTransferObject: T): T {
    emitActionChangeEvent(this.action);
    return dataTransferObject;
  }
}

export function emitActionChangeEvent(
  action: keyof typeof OrderEventBusEventsMap,
): void {
  if (action) {
    eventBusServicePlugin.get().emit(OrderEventBusEventsMap[action]);
  }
}
