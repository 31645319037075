import { apiPlugin } from '@/features/core/api';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';

import { ConfigurationApiClient } from './configuration';
import { authServicePlugin } from '@/features/core/auth';
import { registerConfigurationApi } from './configurationRegister';

// TODO: Both shouldn't be used from external. Please remove
export * from './configuration';
export * from './configurationRegister';

export const configurationApiPlugin =
  ProviderPluginFactory.create<ConfigurationApiClient>({
    key: Symbol('ConfigurationApiClient'),
    defaultFactory: {
      create: () =>
        new ConfigurationApiClient(apiPlugin.get(), authServicePlugin.get()),
    },
  });

export const configurationApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const configurationApi = configurationApiPlugin.get();
  registerConfigurationApi(
    onlineEntityRepositoryRegistryPlugin.get(),
    configurationApi,
  );
});
