<template>
  <div
    :class="[
      'radio-check',
      {
        disabled: isAllDisabled,
      },
    ]"
    v-for="(option, index) in options"
    :key="option.text"
    :data-e2e="dataE2e"
  >
    <input
      class="radio-check__input"
      :id="id + index"
      :name="id"
      type="radio"
      :value="option.value"
      v-model="selected"
      @input="updateInput"
      :disabled="isAllDisabled"
      :data-e2e="`radio-check-${option.e2eLabel}`"
    />
    <label
      :for="id + index"
      class="radio-check__label"
      :data-e2e="`radio-check-${option.e2eLabel}-label`"
      >{{ $t(option.label) }}</label
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'RadioGroup',
  emits: ['selectedChange'],
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: Array as () => {
        value: string | number | boolean | undefined;
        label: string;
        e2eLabel: string;
      }[],
      required: true,
    },
    isAllDisabled: {
      type: Boolean,
      default: false,
    },
    isPreSelected: {
      type: Boolean,
      default: true,
    },
    dataE2e: {
      type: String,
      required: false,
    },
  },
  setup: function (props, { emit }) {
    const defaultValue = props.isPreSelected
      ? props.value ?? props.options[0].value
      : null;
    const selected = ref(defaultValue);
    emit('selectedChange', selected.value);

    watch(
      () => props.value,
      () => {
        selected.value = props.value;
      },
    );
    return {
      props,
      selected,
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateInput(event: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.$emit('selectedChange', event.target._value);
    },
  },
});
</script>
<style lang="scss" scoped>
.radio-check {
  margin-top: 22px;

  &__input {
    position: relative;
    margin: 0 8px 0 0;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #fff;
    border: 1px solid $input-focus-border-color;
    border-radius: 50%;
    vertical-align: top;
    box-sizing: border-box;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    &:checked::before {
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
      width: 12px;
      height: 12px;
      background: $input-focus-border-color;
      border-radius: 50%;
      content: '';
    }
  }

  &__label {
    font-size: 18px;
    line-height: 26px;
  }

  &.disabled {
    .radio-check__input {
      border-color: #d6d8da;
      &__label {
        color: #d6d8da;
      }
      &:checked::before {
        background-color: #d6d8da;
      }
    }
    .radio-check__label {
      color: #d6d8da;
    }
  }
}
</style>
