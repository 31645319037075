import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import type { ComponentPublicInstance, PropType } from 'vue';
import { ref } from 'vue';

import ErrorPreview from './components/ErrorPreview.vue';
import ErrorStack from './components/ErrorStack.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Error',
  props: {
    error: {},
    instance: {},
    info: {}
  },
  setup(__props: any) {

// FIXME: should not be on root of the feature


// FIXME: Do not use process envs diretly in a vue component. Use e.g. a plugin to hold the value
const stacktraceOpen = ref(process.env.VUE_APP_CI === 'true');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!stacktraceOpen.value)
      ? (_openBlock(), _createBlock(ErrorPreview, {
          key: 0,
          error: _ctx.error,
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (stacktraceOpen.value = true))
        }, null, 8, ["error"]))
      : _createCommentVNode("", true),
    (stacktraceOpen.value)
      ? (_openBlock(), _createBlock(ErrorStack, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (stacktraceOpen.value = false)),
          error: _ctx.error,
          instance: _ctx.instance,
          info: _ctx.info
        }, null, 8, ["error", "instance", "info"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})