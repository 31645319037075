import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = { class: "order-list__orders" }
const _hoisted_4 = ["data-e2e"]
const _hoisted_5 = ["data-e2e"]

import type { Ref } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { $t } from '@/i18n';
import { lastPosition } from '@/features/core/router';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { authServicePlugin } from '@/features/core/auth';
import { EntityUpdatedEvent } from '@/features/core/entity-repository';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import type { StorageSortDirection } from '@/features/core/storage';
import { BooleanNumber } from '@/features/core/storage';
import {
  AppHeader,
  Badge,
  CustomerNotePopup,
  Dialog,
  ItemsGroup,
  ScreenLoading,
  Search,
  Tab,
} from '@/features/ui/components';
import { formatDate } from '@/composables/useDateFormat';
import type { UseOrdersFilters } from '@/features/orders';
import { ordersServicePlugin } from '@/features/orders';
import type { Cancellable } from '@/utils/types';
import { vueBarcodePlugin } from '@/features/barcode';
import { configurationServicePlugin } from '@/features/configuration';
import {
  OrderMetadata,
  orderMetadataServicePlugin,
  showAsUnseenMinutes,
} from '@/features/orderMetadata';
import { deepClone } from '@/utils/helpers/deepClone';
import type { BasicTemperatureClass } from '@/features/products';
import { basicTemperatureClasses } from '@/features/products';
import { useTransmissions } from '@/features/transmissions';
import { startHandoverProcess } from '@/features/orders/composables';
import { errorPlugin } from '@/features/core/errors';
import OrderCard from '../components/OrderCard.vue';
import {
  NoOrdersFallback,
  OrderFilter,
  PullToRefresh,
  SearchResultFeedback,
} from '../components';
import { useCheckInOrders, useOnlineStatus, useOrders } from '../composables';
import { Order } from '../entities';
import { isDueSoonOrder, isOrderHandover } from '..';
import { BarcodeScanEvent } from '../checkin';
import type { Tab as TabType } from '../types';
import {
  FallbackType,
  OrderActionStatus,
  OrderLocalStatus,
  tabs,
  tabsList,
} from '../types';
import {
  usePerformanceTracker,
  PerformanceThresholdEnum,
} from '@/features/performance-tracker';
import { dataFetchQueueServicePlugin } from '@/features/data-fetch-queue';
import {
  CheckInBanner,
  CheckInBannerContextEnum,
} from '@/features/check-in-banner';


//TODO <script setup> notation doesnt support beforeRouteEnter hook
import { defineComponent } from 'vue';
import { useOrderList } from '../composables';

const __default__ = defineComponent({
  beforeRouteEnter(to, from, next) {
    const { redirectGuard } = useOrderList();
    redirectGuard(next);
  },
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'OrdersList',
  setup(__props) {

const searchTitle = $t('pages.order-overview.search-title.text', {
  date: formatDate(new Date()),
});
const orderFilter = ref<null | typeof OrderFilter>(null);
const router = useRouter();
const route = useRoute();
const searchRef: Ref<InstanceType<typeof Search> | null> = ref(null);
const disabledProcess = ref(false);
const pickingInProgressOrdersVisible: Ref<boolean> = ref(false);
const isRefreshLoading: Ref<boolean> = ref(false);

const isUserTokenRefreshing = ref(false);
const isPageLoadingVisible = computed(
  () => isUserTokenRefreshing.value || loading.value,
);
const screenLoadingTitle = computed(() =>
  isUserTokenRefreshing.value
    ? $t('pages.order-overview.refreshing-user-token.text')
    : '',
);

const handleBeforeRouteLeave = (to: RouteLocationNormalized): boolean => {
  if (to.meta?.preventRedirectFromOrdersList) {
    notificationPlugin.get().show({
      text: $t('pages.order-overview.cant-go-back.text'),
      type: NotificationType.Error,
      duration: 2000,
    });
    return false;
  }

  return true;
};

onBeforeRouteLeave((to: RouteLocationNormalized) => handleBeforeRouteLeave(to));

const {
  orders,
  loadOrders,
  searchOrders,
  loading: ordersLoading,
  searchedOrders,
} = useOrders();

const { orders: pickingInProgressOrders, loadOrders: loadOrdersInProgress } =
  useOrders();

// #region checked in
// NOTE: this "useOrders" use for "checked in" orders. We can't get the "checked in" orders to separate from the general order list. Because general list is limited to 100 orders.
const {
  orders: checkedInOrders,
  loadOrders: loadCheckinOrders,
  searchOrders: searchCheckinOrders,
  loading: checkedInOrdersLoading,
} = useOrders();
// #endregion

const {
  checkInBanner,
  checkCheckedInOrders,
  firstCheckedInOrder,
  showBanner,
  checkinOrdersLength,
} = useCheckInOrders();

const { startTracking, stopTracking } = usePerformanceTracker();

const { search, searchIsActive, activatedSearch, handleRoute } = useOrderList();
const loading = computed(
  () => ordersLoading.value || checkedInOrdersLoading.value,
);

// #region filters
const temperatureClasses: Ref<BasicTemperatureClass[]> = ref(
  deepClone(basicTemperatureClasses),
);

const onChangeFilter = async (
  listOrder: string[],
  sortBy: string,
  sort: StorageSortDirection,
  pickingInProgressOrdersToggled: boolean,
) => {
  pickingInProgressOrdersVisible.value = pickingInProgressOrdersToggled;
  updateFilterTempClasses(listOrder);

  filteringStatus.value.temperatureClasses = listOrder;
  filteringStatus.value.sortBy =
    listOrder.length === 1 && sortBy === 'itemsCount.total'
      ? `itemsCount.${listOrder[0]}`
      : sortBy;
  filteringStatus.value.sort = sort;

  if (pickingInProgressOrdersVisible.value) {
    await loadOrdersInProgress({
      ...filteringStatus.value,
      status: currentTab.value.status,
      localStatus: [OrderLocalStatus.PickingInProgress],
    });
  } else {
    pickingInProgressOrders.value = [];
  }
  if (currentTab.value.status === OrderActionStatus.picking) {
    await loadOrders(filteringStatus.value);
  }

  window?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const updateFilterTempClasses = (activeClasses: string[]) => {
  temperatureClasses.value = temperatureClasses.value.map((tempClass) => {
    const isActive = activeClasses.includes(tempClass.name);
    return { ...tempClass, active: isActive };
  });
};

const clearFilter = () => {
  updateFilterTempClasses([]);
  filteringStatus.value.temperatureClasses = undefined;
  filteringStatus.value.sortBy = undefined;
  filteringStatus.value.sort = undefined;
};
// #endregion

// #region tabs
const currentTab = computed(
  () => tabsList.find((tab) => route.hash.includes(tab.linkTo)) ?? tabs.Picking,
);

const isHandoverTab = computed(() => currentTab.value === tabs.Handover);

const filteringStatus: Ref<UseOrdersFilters> = ref({
  status: currentTab.value.status,
  localStatus: [currentTab.value.localStatus, OrderLocalStatus.Paused],
});

const defaultHandoverFilters = {
  temperatureClasses: undefined,
  sortBy: undefined,
  sort: undefined,
  status: tabs.Handover.status,
  localStatus: [tabs.Handover.localStatus, OrderLocalStatus.Paused],
};

const changeTab = async (tab: TabType) => {
  filteringStatus.value.status = tab.status;
  filteringStatus.value.localStatus = [
    tab.localStatus,
    OrderLocalStatus.Paused,
  ];

  // NOTE: We don't use "isHandoverTab" because the route hash doesn't change until then.
  if (tab === tabs.Handover) {
    // NOTE: We do not provide a picker the filter for 'handover' orders, at the same time we have to keep a filter for 'picking' orders
    await Promise.all([
      loadCheckinOrders({
        ...defaultHandoverFilters,
        sortBy: 'checkIn.timestamp',
        isCheckedIn: BooleanNumber.True,
      }),
      loadOrders({
        ...defaultHandoverFilters,
        isCheckedIn: BooleanNumber.False,
      }),
    ]);
  } else {
    await loadOrders(filteringStatus.value);
    if (pickingInProgressOrdersVisible.value) {
      await loadOrdersInProgress({
        ...filteringStatus.value,
        localStatus: [OrderLocalStatus.PickingInProgress],
      });
    }
  }

  await checkCheckedInOrders();

  window.scrollTo(0, lastPosition.top);
};

watch(currentTab, (tab: TabType) => changeTab(tab), { immediate: true });
// #endregion

// #region Search
const searchFocused = ref(false);
const toggleSearch = () => (searchFocused.value = !searchFocused.value);

const isSearching = computed(
  () => searchIsActive.value && search.value?.length >= 2,
);

watch(search, (newValue) => {
  if (isHandoverTab.value) {
    void searchCheckinOrders({
      search: newValue,
      searchQueries: ['orderReference', 'pickupCode'],
      ...filteringStatus.value,
      isCheckedIn: BooleanNumber.True,
    });
    void searchOrders({
      search: newValue,
      searchQueries: ['orderReference', 'pickupCode'],
      ...filteringStatus.value,
    });
  } else {
    const searchParams = {
      search: newValue,
      searchQueries: ['orderReference'],
      ...filteringStatus.value,
    };

    if (pickingInProgressOrdersVisible.value) {
      searchParams.localStatus = [
        ...(filteringStatus.value.localStatus || []),
        OrderLocalStatus.PickingInProgress,
      ];
    }

    void searchOrders(searchParams);
  }
});
// #endregion

const { isOnline } = useOnlineStatus();
let offlineInfoToast: Cancellable | undefined;
let offlineErrorToast: Cancellable | undefined;

const { showOfflineDialog } = authServicePlugin.get();

const updateOfflineInfoToast = (): void => {
  if (showOfflineDialog.value) return;
  if (isOnline.value) {
    offlineInfoToast?.cancel();
  } else {
    offlineInfoToast = notificationPlugin.get().show({
      text: $t('common.device-offline.text'),
      type: NotificationType.Info,
      duration: -1,
    });
  }
};
const updateOfflineErrorToast = (): void => {
  if (isOnline.value) {
    offlineErrorToast?.cancel();
  }
  // Do not display automatically when offline
};
const showOfflineErrorToast = (): void => {
  offlineErrorToast = notificationPlugin.get().show({
    text: $t('common.device-offline.text'),
    type: NotificationType.Error,
    duration: 2000,
  });
};

// Watching isOnline variable throws Vue warning while testing if it's not computed property.
const isOnlineComputed = computed(() => isOnline.value);
watch(isOnlineComputed, () => {
  updateOfflineInfoToast();
  updateOfflineErrorToast();
});

const updateOrders = async () => {
  await loadOrders(filteringStatus.value);
};

const forceUpdateOrderList = (orderIds: string[]) => {
  eventBusServicePlugin.get().emit(
    new EntityUpdatedEvent([
      {
        entity: Order,
        updated: true,
        ids: orderIds,
      },
    ]),
  );
};

const fetchNewOrders = async (ids: string[] | null = null) => {
  if (disabledProcess.value || isRefreshLoading.value) {
    return;
  }

  if (!isOnline.value) {
    showOfflineErrorToast();
    return;
  }

  const joinIdsOrEmpty: string = ids ? ids.join(', ') : '';

  disabledProcess.value = true;
  isRefreshLoading.value = true;

  try {
    startTracking(
      `fetch-new-orders-${joinIdsOrEmpty}`,
      PerformanceThresholdEnum.API_CALL,
    );

    const fetchedOrders = await ordersServicePlugin
      .get()
      .fetchOrdersByIds(ids, false);

    const fetchedOrderIds = fetchedOrders.map((o) => o.id);
    forceUpdateOrderList(fetchedOrderIds);

    /* If all orders were fetched, DataFetchQueue is outdated and a new request is unnecessary */
    if (ids === null) {
      await dataFetchQueueServicePlugin.get().removeAllOfEntity(Order);
    }
  } catch (error) {
    errorPlugin.get().handle(error);
  } finally {
    stopTracking(`fetch-new-orders-${joinIdsOrEmpty}`);
  }

  disabledProcess.value = false;
  isRefreshLoading.value = false;
};

const showInProgressOrders = computed(
  () =>
    !isHandoverTab.value &&
    pickingInProgressOrdersVisible.value &&
    pickingInProgressOrders.value.length,
);

const isNoOrdersFallbackVisible = computed(
  () =>
    !loading.value &&
    !orders.value.length &&
    !showInProgressOrders.value &&
    (!isHandoverTab.value ||
      (isHandoverTab.value && !checkedInOrders.value.length)),
);

const isPausedOrder = (status: OrderLocalStatus) =>
  status === OrderLocalStatus.Paused;

const pausedOrders = computed(() => {
  return orders.value.filter((item) => isPausedOrder(item.localStatus));
});

const openOrders = computed(() => {
  const _openOrders = orders.value.filter(
    (item) => !isPausedOrder(item.localStatus),
  );

  return isHandoverTab.value
    ? _openOrders
    : _openOrders.filter(
        (order) => !isDueSoonOrder(order.startTime, pickingBuffer),
      );
});

const ordersTitle = computed(() => {
  if (searchIsActive.value || !orders.value.length) {
    return 'Results';
  }
  return currentTab.value.status === OrderActionStatus.picking
    ? $t('pages.order-overview.open-orders.text')
    : $t('pages.order-overview.check-in-waiting.text');
});

const isFiltering = computed(
  () =>
    filteringStatus.value.temperatureClasses?.length ||
    (filteringStatus.value.sortBy &&
      filteringStatus.value.sortBy !== 'startTime') ||
    (filteringStatus.value.sort && filteringStatus.value.sort !== 'asc'),
);

const fallbackType = computed(() => {
  if (currentTab.value.status === OrderActionStatus.picking) {
    if (isFiltering.value) {
      return FallbackType.noFilteredPickingOrders;
    } else {
      return FallbackType.noPickingOrders;
    }
  } else {
    return FallbackType.noHandoverOrders;
  }
});

const resetFilters = async () => {
  clearFilter();
  if (orderFilter.value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    orderFilter.value.clearFilter();
  }
  await loadOrders(filteringStatus.value);
};

const barcodeService = vueBarcodePlugin.get();

const getBarcodeData = (barcode: string) => {
  const barcodeData = barcode.split('|');
  return {
    tabHash: `#${barcodeData[0]}`,
    orderReference: barcodeData[1],
  };
};

barcodeService.onBarcode({
  async next(barcode: string) {
    const { orderReference, tabHash } = getBarcodeData(barcode);
    await router.push({ path: '/', hash: tabHash });
    if (searchRef?.value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!searchRef.value.showSearch) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        searchRef.value.toggleSearch();
      }
      search.value = orderReference;

      eventBusServicePlugin.get().emit(new BarcodeScanEvent());
    }
  },

  validationError() {
    notificationPlugin.get().show({
      text: $t('errors.barcode.not-supported-qr-code.message'),
      type: NotificationType.Error,
    });
  },
  pattern: 'CheckinRegexp',
});

const interactedOrder = ref({});
const setInteractedOrder = (order?: Order) => {
  if (order) {
    interactedOrder.value = order;
  }
};

const cardsRefs: Ref<{ [key: string]: typeof OrderCard }> = ref({});

const setCardsRefs = (element: typeof OrderCard, cardId: string) => {
  cardsRefs.value[`card-${cardId}`] = element;
};

const startOrder = async (order: Order) => {
  if (isOrderHandover(order)) {
    await startHandoverProcess(order, disabledProcess);
    return;
  }

  await router.push({
    name: 'picking-start',
    params: {
      id: order.id,
    },
  });
};
// #endregion

// Customer note
const isNotePopupVisible = ref(false);
const toggleNote = (order?: Order) => {
  setInteractedOrder(order);
  isNotePopupVisible.value = !isNotePopupVisible.value;
  checkInBanner.value = !isNotePopupVisible.value;
};

//#region transmissions
const {
  skipTransmission,
  toggleTransmissionsWindowVisibility,
  isTransmissionsModalVisible,
  initTransmissionsCheck,
} = useTransmissions();

onMounted(async () => {
  updateOfflineInfoToast();
  void checkCheckedInOrders();
  pickingBuffer = await getPickingBuffer();
  stopTracking('bags-position-to-order-list');
  stopTracking('handover-order-to-order-list');
  initTransmissionsCheck();
});
//#endregion

//#region scroll to order
watch(cardsRefs, () => {
  void handleRoute(route, cardsRefs).then((orderId: string | null) => {
    if (orderId !== null && isHandoverTab.value && isOnline.value) {
      void fetchNewOrders([orderId]);
    }
  });
});

watch(
  () => route,
  (to: RouteLocationNormalized, from: RouteLocationNormalized | undefined) => {
    //During initial page loading the cardsRefs watcher will handle the route
    if (from === undefined) {
      return;
    }

    void handleRoute(to, cardsRefs).then((orderId: string | null) => {
      if (orderId !== null && isHandoverTab.value && isOnline.value) {
        void fetchNewOrders([orderId]);
      }
    });
  },
  { deep: true },
);
//#endregion

// #region Due Soon
const getPickingBuffer = async () =>
  await configurationServicePlugin
    .get()
    .getFeatureOption('picking', 'pickingBuffer', 'number');
let pickingBuffer = 0;

const dueSoon = computed(() =>
  !isHandoverTab.value
    ? orders.value.filter(
        (order) =>
          !isPausedOrder(order.localStatus) &&
          isDueSoonOrder(order.startTime, pickingBuffer),
      )
    : [],
);
// #endregion

// #region 'new' incoming orders
const orderMetadataService = orderMetadataServicePlugin.get();

const isNewOrderBadgeVisible = (metadata: OrderMetadata) => {
  return (
    !isHandoverTab.value &&
    orderMetadataService?.isOrderUnseen(
      metadata ?? OrderMetadata.from({ id: '', seen: true }),
    )
  );
};

onBeforeUnmount(async () => {
  offlineInfoToast?.cancel();
  offlineErrorToast?.cancel();
  await setOrderMetadata(Array.from(bufferedNewOrdersMetadata.values()));
  pickingInProgressOrders.value = [];
  checkedInOrders.value = [];
  orders.value = [];
});

const setOrderMetadata = async (ordersMetadata: OrderMetadata[]) => {
  const currentDate = new Date();
  const untilDate = new Date(
    currentDate.setMinutes(currentDate.getMinutes() + showAsUnseenMinutes),
  );

  const newOrdersMetadata = ordersMetadata.map((orderMetadata) => {
    if (!orderMetadata?.id) {
      return;
    }

    const newMetadata = OrderMetadata.from({
      id: orderMetadata.id,
      seen: true,
    });

    if (!orderMetadata?.seen) {
      newMetadata.showAsUnseenUntil = untilDate;
    }

    return OrderMetadata.from(newMetadata);
  });

  await orderMetadataService.bulkSave(newOrdersMetadata);
};

const newOrders = computed(() =>
  orders.value.filter(({ orderMetadata }) =>
    isNewOrderBadgeVisible(orderMetadata),
  ),
);

const bufferedNewOrdersMetadata = new Map<string, OrderMetadata>();
watch(newOrders, (orders) => {
  orders.forEach((order) => {
    const metadata = order.orderMetadata;
    if (!metadata?.id) {
      return;
    }

    bufferedNewOrdersMetadata.set(metadata.id, metadata);
  });
});
// #endregion

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(AppHeader), {
      class: _normalizeClass({
      'header__search--active': _unref(searchIsActive),
    }),
      "border-bottom": "",
      fixed: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Search), {
          ref_key: "searchRef",
          ref: searchRef,
          modelValue: _unref(search),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
          placeholderTitle: 
        isHandoverTab.value
          ? _unref($t)('components.search.placeholder.handover')
          : _unref($t)('components.search.placeholder.picking')
      ,
          searchTitle: _unref(searchTitle),
          inputMode: "numeric",
          onFocus: toggleSearch,
          onUpdate: updateOrders,
          onSearchActivated: _unref(activatedSearch)
        }, null, 8, ["modelValue", "placeholderTitle", "searchTitle", "onSearchActivated"]),
        (!_unref(searchIsActive))
          ? (_openBlock(), _createBlock(_unref(Tab), {
              key: 0,
              "active-tab": currentTab.value,
              "link-list": _unref(tabsList),
              class: "tab__wrap"
            }, {
              badge: _withCtx(({ linkTo }) => [
                (linkTo === _unref(tabs).Picking.linkTo && newOrders.value.length)
                  ? (_openBlock(), _createBlock(_unref(Badge), {
                      key: 0,
                      text: newOrders.value.length.toString()
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true),
                (linkTo === _unref(tabs).Handover.linkTo && _unref(checkinOrdersLength))
                  ? (_openBlock(), _createBlock(_unref(Badge), {
                      key: 1,
                      variant: "alert",
                      text: _unref(checkinOrdersLength).toString()
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["active-tab", "link-list"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("section", _hoisted_1, [
      (isPageLoadingVisible.value)
        ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
            key: 0,
            title: screenLoadingTitle.value,
            blur: "",
            fixed: "",
            inFront: ""
          }, null, 8, ["title"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass([
        'orders-list',
        {
          'orders-list--short': _unref(searchIsActive),
          'orders-list--with-banner': _unref(showBanner),
        },
      ]),
        "data-e2e": "orders-list"
      }, [
        true
          ? (_openBlock(), _createBlock(_unref(PullToRefresh), {
              key: 0,
              loading: isRefreshLoading.value,
              trigger: ".container",
              onRefresh: fetchNewOrders
            }, null, 8, ["loading"]))
          : _createCommentVNode("", true),
        (isSearching.value && _unref(searchedOrders).length)
          ? (_openBlock(), _createBlock(_unref(ItemsGroup), {
              key: 1,
              title: `${_unref($t)('components.search-result-feedback.results.text')} (${
          _unref(searchedOrders).length
        })`,
              "data-e2e": "search-orders-list-title"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchedOrders), (order) => {
                  return (_openBlock(), _createBlock(OrderCard, {
                    key: order.id,
                    ref_for: true,
                    ref: (el) => setCardsRefs(el, order.id),
                    card: order,
                    disabledProcess: disabledProcess.value,
                    isTimeDuration: _unref(isDueSoonOrder)(order.startTime, _unref(pickingBuffer)),
                    onGoNext: ($event: any) => (startOrder(order)),
                    onInfoClick: _cache[1] || (_cache[1] = ($event: any) => (toggleNote($event)))
                  }, _createSlots({ _: 2 }, [
                    (isNewOrderBadgeVisible(order.orderMetadata))
                      ? {
                          name: "timeBadge",
                          fn: _withCtx(() => [
                            _createElementVNode("span", {
                              "data-e2e": `new-order-badge-${order.id}`,
                              class: "new-order-badge"
                            }, null, 8, _hoisted_2)
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["card", "disabledProcess", "isTimeDuration", "onGoNext"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          (showInProgressOrders.value)
            ? (_openBlock(), _createBlock(_unref(ItemsGroup), {
                key: 0,
                title: `${_unref($t)(
            'pages.order-overview.picking-in-progress-title.text',
          )} (${_unref(pickingInProgressOrders).length})`,
                "data-e2e": "orders-in-progress-list-title"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pickingInProgressOrders), (order) => {
                    return (_openBlock(), _createBlock(OrderCard, {
                      key: order.id,
                      ref_for: true,
                      ref: (el) => setCardsRefs(el, order.id),
                      card: order,
                      disabledProcess: disabledProcess.value,
                      onGoNext: ($event: any) => (startOrder(order)),
                      onInfoClick: _cache[2] || (_cache[2] = ($event: any) => (toggleNote($event)))
                    }, null, 8, ["card", "disabledProcess", "onGoNext"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          (!isHandoverTab.value && pausedOrders.value.length)
            ? (_openBlock(), _createBlock(_unref(ItemsGroup), {
                key: 1,
                title: `Not completed (${pausedOrders.value.length})`,
                "data-e2e": "paused-orders-list-title"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pausedOrders.value, (order) => {
                    return (_openBlock(), _createBlock(OrderCard, {
                      key: order.id,
                      ref_for: true,
                      ref: (el) => setCardsRefs(el, order.id),
                      card: order,
                      disabledProcess: disabledProcess.value,
                      onGoNext: ($event: any) => (startOrder(order)),
                      onInfoClick: _cache[3] || (_cache[3] = ($event: any) => (toggleNote($event)))
                    }, null, 8, ["card", "disabledProcess", "onGoNext"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_unref(ItemsGroup), {
            title: `Due Soon (${dueSoon.value.length})`,
            "data-e2e": "due-soon-list-title"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dueSoon.value, (order) => {
                return (_openBlock(), _createBlock(OrderCard, {
                  key: order.id,
                  ref_for: true,
                  ref: (el) => setCardsRefs(el, order.id),
                  card: order,
                  disabledProcess: disabledProcess.value,
                  isTimeDuration: "",
                  onGoNext: ($event: any) => (startOrder(order)),
                  onInfoClick: _cache[4] || (_cache[4] = ($event: any) => (toggleNote($event)))
                }, _createSlots({ _: 2 }, [
                  (isNewOrderBadgeVisible(order.orderMetadata))
                    ? {
                        name: "timeBadge",
                        fn: _withCtx(() => [
                          _createElementVNode("span", {
                            "data-e2e": `new-order-badge-${order.id}`,
                            class: "new-order-badge"
                          }, null, 8, _hoisted_4)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["card", "disabledProcess", "onGoNext"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["title"]), [
            [_vShow, dueSoon.value.length]
          ]),
          _withDirectives(_createVNode(_unref(ItemsGroup), {
            title: `${_unref($t)('pages.order-overview.checkin-title.text')} (${
            _unref(checkedInOrders).length
          })`,
            "data-e2e": "checked-in-orders-list-title"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(checkedInOrders), (order) => {
                return (_openBlock(), _createBlock(OrderCard, {
                  key: order.id,
                  ref_for: true,
                  ref: (el) => setCardsRefs(el, order.id),
                  card: order,
                  disabledProcess: disabledProcess.value,
                  onGoNext: ($event: any) => (startOrder(order)),
                  onInfoClick: _cache[5] || (_cache[5] = ($event: any) => (toggleNote($event)))
                }, null, 8, ["card", "disabledProcess", "onGoNext"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["title"]), [
            [_vShow, isHandoverTab.value && _unref(checkedInOrders).length]
          ]),
          _withDirectives(_createVNode(_unref(ItemsGroup), {
            title: `${ordersTitle.value} (${openOrders.value.length})`,
            "data-e2e": "orders-list-title"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(openOrders.value, (order) => {
                return (_openBlock(), _createBlock(OrderCard, {
                  key: order.id,
                  ref_for: true,
                  ref: (el) => setCardsRefs(el, order.id),
                  card: order,
                  disabledProcess: disabledProcess.value,
                  onGoNext: ($event: any) => (startOrder(order)),
                  onInfoClick: _cache[6] || (_cache[6] = ($event: any) => (toggleNote($event)))
                }, _createSlots({ _: 2 }, [
                  (isNewOrderBadgeVisible(order.orderMetadata))
                    ? {
                        name: "timeBadge",
                        fn: _withCtx(() => [
                          _createElementVNode("span", {
                            "data-e2e": `new-order-badge-${order.id}`,
                            class: "new-order-badge"
                          }, null, 8, _hoisted_5)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["card", "disabledProcess", "onGoNext"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["title"]), [
            [_vShow, openOrders.value.length]
          ])
        ], 512), [
          [_vShow, !_unref(searchIsActive) && !isNoOrdersFallbackVisible.value]
        ])
      ], 2), [
        [_vShow, !isNoOrdersFallbackVisible.value]
      ]),
      _withDirectives(_createVNode(_unref(SearchResultFeedback), {
        isSearching: isSearching.value,
        ordersAmount: _unref(searchedOrders).length,
        searchHintText: 
        isHandoverTab.value
          ? _unref($t)('components.search-feedback.hint-handover.text')
          : _unref($t)('components.search-feedback.hint-picking.text')
      
      }, null, 8, ["isSearching", "ordersAmount", "searchHintText"]), [
        [_vShow, _unref(searchIsActive)]
      ]),
      (!_unref(searchIsActive) && isNoOrdersFallbackVisible.value)
        ? (_openBlock(), _createBlock(_unref(NoOrdersFallback), {
            key: 1,
            fallbackType: fallbackType.value,
            onButtonClick: resetFilters
          }, null, 8, ["fallbackType"]))
        : _createCommentVNode("", true)
    ]),
    (!searchFocused.value && !_unref(searchIsActive) && !isHandoverTab.value)
      ? (_openBlock(), _createBlock(_unref(OrderFilter), {
          key: 0,
          ref_key: "orderFilter",
          ref: orderFilter,
          "filter-sorting": {
      sortBy: filteringStatus.value?.sortBy,
      sort: filteringStatus.value?.sort,
    },
          "is-banner-active": _unref(showBanner),
          typesOfItem: temperatureClasses.value,
          onChangeFilter: onChangeFilter
        }, null, 8, ["filter-sorting", "is-banner-active", "typesOfItem"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(CustomerNotePopup), {
      customerNote: interactedOrder.value?.cartNote,
      isVisible: isNotePopupVisible.value,
      order: interactedOrder.value,
      onConfirm: toggleNote
    }, null, 8, ["customerNote", "isVisible", "order"]),
    _createVNode(_unref(Dialog), {
      "cancel-text": _unref($t)('components.transmissions.orders-modal.cancel.text'),
      "confirm-text": _unref($t)('components.transmissions.orders-modal.confirm.text'),
      "content-text": _unref($t)('components.transmissions.orders-modal.content.text'),
      "title-text": _unref($t)('components.transmissions.orders-modal.title.text'),
      visible: _unref(isTransmissionsModalVisible),
      onCanceled: _unref(skipTransmission),
      onConfirmed: _cache[7] || (_cache[7] = () => _unref(toggleTransmissionsWindowVisibility)())
    }, null, 8, ["cancel-text", "confirm-text", "content-text", "title-text", "visible", "onCanceled"]),
    (_unref(firstCheckedInOrder))
      ? (_openBlock(), _createBlock(_unref(CheckInBanner), {
          key: 1,
          "checkin-orders-length": _unref(checkinOrdersLength),
          context: _unref(CheckInBannerContextEnum).ORDER_OVERVIEW,
          order: _unref(firstCheckedInOrder),
          "show-banner": _unref(showBanner)
        }, null, 8, ["checkin-orders-length", "context", "order", "show-banner"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})