/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
import { isApiError } from '@/features/core/api/helper/is-api-client-error';
import type { ApiClientError } from '@/features/core/api/types';
import {
  BaseError,
  SomethingWrongError,
} from '@/features/core/errors/error-classes';
import { $t } from '@/i18n';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [UpdateOrdersError.Code]: 'UpdateOrdersError';
    [SaveOrderError.Code]: 'SaveOrderError';
    [PickingAlreadyStartedError.Code]: 'PickingAlreadyStartedError';
    [HandoverAlreadyStartedError.Code]: 'HandoverAlreadyStartedError';
    [CheckCheckinOrdersError.Code]: 'CheckCheckinOrdersError';
  }
}

export const ORDER_ALREADY_STARTED_ERROR_CODES = [
  '122',
  '1221',
  '1222',
  '1223',
];

export class UpdateOrdersError extends SomethingWrongError {
  static Code = 6 as const;
}

export class CheckCheckinOrdersError extends SomethingWrongError {
  static Code = 24 as const;
}

export class SaveOrderError extends SomethingWrongError {
  static Code = 7 as const;

  constructor(
    public readonly errorCode: string,
    public readonly errorDetail: string,
  ) {
    super();
    this.generateMessage();
  }
}

export class PickingAlreadyStartedError extends BaseError {
  static Code = 8 as const;
  static Message = $t('errors.picking-already-started.message');
}

export class HandoverAlreadyStartedError extends BaseError {
  static Code = 9 as const;
  static Message = $t('errors.handover-already-started.message');
}

export class PickingCancelOrCompletedError extends BaseError {
  static Code = 35 as const;
  static Message = $t('errors.picking-cancel-completed.message');
}

export class GetAllOrdersRequestError extends SomethingWrongError {
  static Code = 16 as const;
}

export class GetOrderByIdRequestError extends SomethingWrongError {
  static Code = 17 as const;
}

export class SaveOrderRequestError extends SomethingWrongError {
  static Code = 18 as const;
}

interface ResponseErrorDataItem {
  code: string;
  detail: string;
}

const isResponseErrorDataItem = (
  item: unknown,
): item is ResponseErrorDataItem => {
  return (
    typeof item === 'object' &&
    item !== null &&
    'code' in item &&
    'detail' in item &&
    typeof (item as { code: unknown }).code === 'string' &&
    typeof (item as { detail: unknown }).detail === 'string'
  );
};

interface ResponseErrorData {
  errors: ResponseErrorDataItem[];
}

const isResponseErrorData = (data: unknown): data is ResponseErrorData =>
  typeof data === 'object' &&
  data !== null &&
  'errors' in data &&
  Array.isArray((data as { errors: unknown }).errors) &&
  (data as { errors: unknown[] }).errors.length > 0 &&
  (data as { errors: unknown[] }).errors.every(isResponseErrorDataItem);

export function getErrorCode(error: ApiClientError): string {
  const errorData = error.response?.data;
  if (isResponseErrorData(errorData)) {
    return errorData.errors[0]?.code;
  }
  return '';
}

export function getErrorDetail(error: ApiClientError): string {
  const errorData = error.response?.data;
  if (isResponseErrorData(errorData)) {
    return errorData.errors[0]?.detail;
  }
  return '';
}

function getRequestDataStatus(error: ApiClientError): string {
  const requestData = JSON.parse(error.config?.data);
  return requestData ? String(requestData.data?.attributes?.status) : '';
}

export const isAlreadyInExistedOMSState = (error: unknown): boolean => {
  return isApiError(error) && getErrorCode(error) === '1223';
};

export const isAlreadyCompletedOrCancelledOMSState = (
  error: unknown,
): boolean => {
  return isApiError(error) && getErrorCode(error) === '1224';
};

export const isPickingAlreadyStartedError = (error: unknown): boolean => {
  return (
    isApiError(error) &&
    ORDER_ALREADY_STARTED_ERROR_CODES.includes(getErrorCode(error)) &&
    getRequestDataStatus(error) === 'picking_started'
  );
};

export const isHandoverAlreadyStartedError = (error: unknown): boolean => {
  return (
    isApiError(error) &&
    ORDER_ALREADY_STARTED_ERROR_CODES.includes(getErrorCode(error)) &&
    getRequestDataStatus(error) === 'pickup_started'
  );
};
