import { ref } from 'vue';
import type { Order } from '@/features/orders/entities';
import type { CheckInBannerContextEnum, UseCheckInBanner } from '../types';
import { useStartHandoverProcess } from './useStartHandoverProcess';
import { loggerServicePlugin } from '@/features/core/logger';

export const useCheckInBanner = (
  context: CheckInBannerContextEnum,
): UseCheckInBanner => {
  const { startHandoverProcess } = useStartHandoverProcess(context);

  const loading = ref(false);

  const goToCheckInOrder = async (checkedInOrder: Order): Promise<void> => {
    loggerServicePlugin
      .get()
      .info(`CheckIn banner clicked [order ${checkedInOrder.id}]`, {
        context,
      });

    await startHandoverProcess(checkedInOrder, loading);
  };

  return {
    loading,

    goToCheckInOrder,
  };
};
