import { appCrashServicePlugin, appCrashExecutePlugin } from './plugin';
import { MultiPluginFactory } from '@/features/core/plugin';

export * from './types';
// TODO: Check if it is necessary that service is exported. Plugin shoul be enough, because service worker is not using this service
export * from './services';
export * from './plugin';

export const appCrashPlugin = MultiPluginFactory.with({
  appCrashServicePlugin,
  appCrashExecutePlugin,
});
