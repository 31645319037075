import type { Product } from '@/features/products/entities';
import type { OrderItem } from '@/features/orders/types';
import { extractBarcodeComponents } from './extractBarcodeComponents';
import type {
  FindConcreteItemByBarcodePrefixMatchResult,
  BarcodeFilterFactory,
} from '../types';
import { isItemEntityArray } from './isItemEntity';

export function findConcreteItemByBarcodePrefixMatch(
  target: Product[] | OrderItem[],
  barcode: string,
  filterFactory: BarcodeFilterFactory,
): FindConcreteItemByBarcodePrefixMatchResult {
  const { sku, rawEmbeddedValue } = extractBarcodeComponents(barcode);
  let concreteItem: FindConcreteItemByBarcodePrefixMatchResult['concreteItem'];
  if (sku && rawEmbeddedValue) {
    const filterFunction = filterFactory(sku);
    concreteItem = isItemEntityArray(target)
      ? target.find((item) => filterFunction(item.product))
      : target.find((item) => filterFunction(item));
  }
  return {
    sku,
    rawEmbeddedValue,
    concreteItem,
  };
}
