import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { appCrashServicePlugin } from '@/features/app-crash';
import { loggerServicePlugin } from '@/features/core/logger';
import type { OrderPluginDto } from '../types';

export class AppCrashPlugin<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  public async execute(dataTransferObject: T): Promise<T> {
    await appCrashServicePlugin
      .get()
      .updateProcessedOrderData(dataTransferObject.order);

    loggerServicePlugin
      .get()
      .debug(
        `Order crash feature unset for order ${dataTransferObject.order.id}`,
        {
          order: dataTransferObject.order,
        },
      );

    return dataTransferObject;
  }
}
