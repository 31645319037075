import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrdersService } from '@/features/orders';
import type { OrderEventNames } from '@/features/orders/types';
import type { LoggerService } from '@/features/core/logger';
import type { OptionsEvents, OrderPluginDto } from '../types';

/**
 * Track order event and log it
 **/
export class TrackOrderEventPlugin<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  constructor(
    private ordersService: OrdersService,
    private loggerService: LoggerService,
    private event: OrderEventNames,
    private options: OptionsEvents = {},
  ) {}

  public async execute(dataTransferObject: T): Promise<T> {
    dataTransferObject.order = await this.ordersService.trackEvent(
      dataTransferObject.order,
      this.event,
      this.options,
    );

    this.loggerService.debug(
      `Events added to the order ${dataTransferObject.order.id}.`,
      {
        order: dataTransferObject.order,
      },
    );

    return dataTransferObject;
  }
}
