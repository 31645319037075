import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "timer"
}
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue';
import {
  deviceFeedbackServicePlugin,
  playlistActionRequiredBarcodeScan,
} from '@/features/device-feedback';
import {
  formatTimeMinutesDifferenceToCurrentTime,
  MAX_TIME,
  URGENT_TIME,
  WARNING_TIME,
} from '@/composables/useDateFormat';
import * as icons from '@/features/ui/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChipTime',
  props: {
    time: {},
    labelView: { type: Boolean },
    dataE2e: {},
    timeAgo: { type: Boolean },
    smallView: { type: Boolean }
  },
  emits: ['vibrate'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
let timeCheckInterval = undefined;
const { time } = toRefs(props);
const difference = ref(
  formatTimeMinutesDifferenceToCurrentTime(new Date(time.value)),
);

onMounted(() => {
  timeCheckInterval = setInterval(() => {
    difference.value = formatTimeMinutesDifferenceToCurrentTime(
      new Date(time.value),
    );
    if (difference.value.minutes < 0 || difference.value.minutes >= MAX_TIME) {
      if (timeCheckInterval) clearInterval(timeCheckInterval);
    }
  }, 1000);
});

const variant = computed(() =>
  difference.value.minutes < WARNING_TIME
    ? 'success'
    : difference.value.minutes < URGENT_TIME
    ? 'warning'
    : 'danger',
);

const componentName = computed(() =>
  difference.value.minutes < WARNING_TIME
    ? defineComponent(icons['ClockIcon'])
    : difference.value.minutes < URGENT_TIME
    ? defineComponent(icons['WarningIcon'])
    : defineComponent(icons['OverdueIcon']),
);

const unwatch = watch(difference, async () => {
  if (difference.value.minutes >= WARNING_TIME) {
    emit('vibrate');
  }
  if (
    difference.value.seconds === URGENT_TIME * 60 ||
    difference.value.seconds === WARNING_TIME * 60
  ) {
    await deviceFeedbackServicePlugin
      .get()
      .trigger(playlistActionRequiredBarcodeScan);
  }
});

onBeforeUnmount(() => {
  if (timeCheckInterval) clearInterval(timeCheckInterval);
  unwatch();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.labelView)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(difference.value.text), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
      'chip',
      `chip--${variant.value}`,
      { 'chip--label': _ctx.labelView },
      { 'chip--small': _ctx.smallView },
    ]),
      "data-e2e": _ctx.dataE2e
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(componentName.value), {
        width: _ctx.labelView ? 16 : 20,
        height: _ctx.labelView ? 16 : 20
      }, null, 8, ["width", "height"])),
      (_ctx.labelView)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(variant.value === 'success'
        ? _ctx.$t('components.chip-time.on-time.text')
        : variant.value === 'warning'
        ? _ctx.$t('components.chip-time.urgent.text')
        : _ctx.$t('components.chip-time.overdue.text')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(difference.value.text) + " " + _toDisplayString(_ctx.timeAgo ? _ctx.$t('components.chip-time.min-ago.text') : ''), 1))
    ], 10, _hoisted_2)
  ], 64))
}
}

})