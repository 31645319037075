import type { ConfigurationValueReturnType } from '@/features/configuration/types';

export function transformStringToType(
  value: string | null,
  transformedType: ConfigurationValueReturnType,
): string | number | boolean | string[] {
  let transformedValue;
  switch (transformedType) {
    case 'number': {
      const transformedToNumberValue = value !== null ? parseFloat(value) : NaN;
      transformedValue = isNaN(transformedToNumberValue)
        ? 0
        : transformedToNumberValue;
      break;
    }
    case 'boolean':
      transformedValue = value === 'true';
      break;
    case 'array':
      transformedValue =
        typeof value === 'string' && value !== '' ? value.split(',') : [];
      break;
    default:
      transformedValue = String(value);
      break;
  }
  return transformedValue;
}
