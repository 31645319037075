// FIXME: Configuration can be used by service worker, why there should be no dependency to vue framework. Move the part where ref is needed to a composable
import type { Ref } from 'vue';
import { ref } from 'vue';
import { transformStringToType } from '@/utils/helpers/transformStringToType';
import type { Storage } from '@/features/core/storage';
import type { ConfigurationApiClient } from '../api';
import { Configuration } from '../entities';
import type { ConfigurationValueReturnType, TypeFromString } from '../types';

export class ConfigurationService {
  constructor(
    private storage: Storage,
    private configurationApi: ConfigurationApiClient,
  ) {}

  async fetchAllConfigurations(): Promise<Configuration[]> {
    await this.storage.removeAll(Configuration);
    const configurations = await this.configurationApi.getAll();
    const savedConfigurations = await this.storage.bulkSave(configurations);
    return savedConfigurations;
  }

  async isFeatureActive(featureId: string): Promise<Ref<boolean>> {
    const feature = await this.storage.getById(Configuration, {
      id: featureId,
    });
    return feature ? ref(feature.active) : ref(false);
  }

  async getFeatureOption<T extends ConfigurationValueReturnType>(
    featureId: string,
    optionId: string,
    returnType: T,
  ): Promise<TypeFromString<T>> {
    const feature = await this.storage.getById(Configuration, {
      id: featureId,
    });
    if (feature) {
      const featureOption = feature.options.find(
        (option) => option.id === optionId,
      );
      if (featureOption) {
        return transformStringToType(
          featureOption.values,
          returnType,
        ) as TypeFromString<T>;
      }
    }
    return transformStringToType('', returnType) as TypeFromString<T>;
  }

  async clearAllConfigurations(): Promise<void> {
    await this.storage.removeAll(Configuration);
  }
}
