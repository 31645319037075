import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { LoggerService } from '@/features/core/logger';
import { updateOrderTimestamp } from '@/utils/helpers/updateOrderTimestamp';
import type { OrderLocalStatus } from '@/features/orders';
import type { OrderPluginDto } from '../types';

/**
 * Update local status
 **/
export class UpdateLocalStatusPlugin<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  constructor(
    private loggerService: LoggerService,
    private localStatus: OrderLocalStatus,
  ) {}

  public execute(dataTransferObject: T): T {
    dataTransferObject.order.localStatus = this.localStatus;

    this.loggerService.debug(
      `Order status changed to ${dataTransferObject.order.localStatus} for order ${dataTransferObject.order.id}`,
      { order: dataTransferObject.order },
    );

    updateOrderTimestamp(dataTransferObject.order);

    this.loggerService.debug(
      `Order timestamp changed for order ${dataTransferObject.order.id}`,
      {
        order: dataTransferObject.order,
      },
    );

    return dataTransferObject;
  }
}
