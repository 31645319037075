import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { $d } from '@/i18n';

// TODO: Move the generic functions to utils, because they are not composables. The functions which are using i18n can be "seen" as composables and they can stay here. This Composable is indirect imported into Service worker, why this refactoring is needed

/**
 * Returns the formatted time string between two dates.
 * Examples: "666 min ago" or "In 666 min".
 *
 */
export function formatTimeDifference(
  laterDate: number | string | Date,
  earlierDate: Date | number,
): string {
  const timeSince = differenceInMinutes(
    new Date(laterDate),
    new Date(earlierDate),
    {
      roundingMethod: 'ceil',
    },
  );

  return timeSince > 0
    ? `In ${timeSince} min`
    : `${Math.abs(timeSince)} min ago`;
}

// FIXME: These values are related to Order Picking. They shouldn't be in these common composable
export const MAX_TIME = 60;
export const URGENT_TIME = 4;
export const WARNING_TIME = 3;

interface FormatMinuteDifference {
  minutes: number;
  text: string;
  seconds: number;
}

export function formatTimeMinutesDifferenceToCurrentTime(
  date: Date | number,
): FormatMinuteDifference {
  const secondsSince = differenceInSeconds(new Date(), new Date(date), {
    roundingMethod: 'ceil',
  });

  if (secondsSince < 0) {
    return {
      minutes: -1,
      text: '0:00',
      seconds: secondsSince,
    };
  }

  const minutes = Math.floor(secondsSince / 60);

  const minutesText = minutes.toString().padStart(2, '0'),
    sec = Math.floor(secondsSince % 60)
      .toString()
      .padStart(2, '0');

  return {
    minutes,
    text: minutes >= 60 ? '60:00+' : minutesText + ':' + sec,
    seconds: secondsSince,
  };
}

export function formatHours(
  date: Date,
  leadingZero = false,
  format24H = false,
): string {
  if (format24H) {
    return $d(date, 'short-time');
  }
  if (leadingZero) {
    return $d(date, 'short-time-leading-zero');
  } else {
    return $d(date, 'short-time-12hr').toLowerCase();
  }
}

export function formatDate(date: Date): string {
  return $d(date, 'short');
}

export function formatDateTime(date: Date): string {
  return formatHours(date) + ' - ' + formatDate(date);
}
