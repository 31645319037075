import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckIcon',
  props: {
  fillColor: {
    type: String,
    default: '#0D3A93',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M20.7805 6.41053C20.1947 5.82474 19.245 5.82474 18.6592 6.41053L17.7435 7.3262L17.7395 7.32215L8.7839 16.2777L6.4018 13.8755L6.40146 13.8741L5.3408 12.8135C4.75502 12.2277 3.80527 12.2277 3.21948 12.8135L5.24766 14.8417L8.24909 17.8687C8.38954 18.0104 8.58062 18.0902 8.78008 18.0906C8.97954 18.0911 9.17095 18.012 9.31199 17.871L18.6482 8.5348L18.6592 8.53185L20.7805 6.41053Z",
      fill: __props.fillColor
    }, null, 8, _hoisted_2)
  ]))
}
}

})