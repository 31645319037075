import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { apiPlugin } from '@/features/core/api';
import { authServicePlugin } from '@/features/core/auth';
import { deviceIdServicePlugin } from '@/features/device-id';
import { ServiceWorkerEventTypes } from '@/features/service-worker';
import type {
  SaveLogsToMemoryTransporterInterface,
  Transporter,
} from './types';
import { BackendLoggingTransporter } from './services/backend-logging-transporter';
import { BrowserLoggingTransporter } from './services/browser-logging-transporter';
import { DynatraceTransporter } from './services/dynatrace-transporter';
import { NewRelicTransporter } from './services/new-relic-transporter';
import { NoopNewRelicTransporter } from './services/noop-new-relic-transporter';
import { logsEntityPlugin } from './entities';
import { LoggerApiClient } from './api';
import { SaveLogsToMemoryTransporter } from './services/save-logs-to-memory-transporter';
import { loggerServicePlugin } from '@/features/core/logger/plugin';

export const backendLoggingTransporterPlugin =
  ProviderPluginFactory.create<BackendLoggingTransporter>({
    key: Symbol('backendLoggingTransporter'),
    defaultFactory: {
      create: () => {
        return new BackendLoggingTransporter(
          storagePlugin.get(),
          new LoggerApiClient(
            apiPlugin.get(),
            deviceIdServicePlugin.get(),
            authServicePlugin.get(),
          ),
          authServicePlugin.get(),
        );
      },
    },
  });

export const loggerExecutePlugin = ExecPluginFactory.fn(async () => {
  loggerServicePlugin.get().addTransporters(getTransporters());

  loggerServicePlugin.get().setLoggerInterceptors(apiPlugin.get());
  backendLoggingTransporterPlugin.get().flushLogsPeriodically();

  const registration = await navigator.serviceWorker.ready;
  registration?.active?.postMessage({
    version: process.env.VUE_APP_VERSION,
    type: ServiceWorkerEventTypes.SetAppVersion,
  });
});

const getTransporters = () => {
  const transporters: Transporter[] = [
    (window.browserLogging = new BrowserLoggingTransporter()),
  ];
  transporters.push(backendLoggingTransporterPlugin.get());

  if (process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT) {
    transporters.push(
      // FIXME: NoopNewRelicTransporter will be never used, why it is added here?
      process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT
        ? new NewRelicTransporter()
        : new NoopNewRelicTransporter(),
    );
    transporters.push(new DynatraceTransporter());
  }

  if (process.env.VUE_APP_CI) {
    transporters.push(
      (window.memoryLogsHistory =
        new SaveLogsToMemoryTransporter() as SaveLogsToMemoryTransporterInterface),
    );
  }

  return transporters;
};

export const loggerTransportersPlugin = MultiPluginFactory.with({
  logsEntityPlugin,
  backendLoggingTransporterPlugin,
  loggerExecutePlugin,
});
