type CreditsList = { list: string[] };
export default (): CreditsList => {
  const list = [
    'Harinder Mattu',
    'Andrew Chekanov ',
    'Thanh Nhat Vu',
    'Andrii Kotiuk',
    'Zuzana Jirakova',
    'Benny Hierl',
    'Bernd Kortmann',
    'Benjamin Krammer',
    'Barbara Riegger',
    'Haris Selmanovic',
    'Vitalii Ivanov',
    'Davyd Sporysh',
    'Lena Degtyarenko',
    'Viktor Prytyka',
    'Aurelijus Useckas',
    'David Vigvari',
    'Zoltan Virag',
    'Carlos Mayo Hernandez',
    'Bohdan Yevtukhov',
    'Tracy Kaitano',
    'Dmytro Tsybenko',
    'Alina Kliuchko',
    'Fabian Glittenberg',
    'Sherif Ibraheem ',
    'Yan Kutomanov',
    'Alex Malkevich',
    'Daniel Hansmann',
    'Stefan Neuhaus',
    'Andrey Tkachenko',
    'Martin Kogut',
    'Shadi Hariri',
    'Moritz Dörrenberg',
    'Evgenij Chepur',
    'Ramji Santhanam',
    'Tim Mandel',
    'Vladyslav Vakhnovskyi',
    'Eugen Galitsky',
    'Peter Balla',
    'Renato D. Beltrão',
    'Claudia Koh',
    'Sarah Schlecht',
    'Tugay Akkaya',
    'Warren Stephen',
    'Laszlo Antal',
    'Janos Mihalyfi',
    'Szilvia Csonka',
    'Peter Csala',
    'Oksana Nechipor',
    'Istvan Pataki',
    'Adam Gobl',
    'Rene Jasmund',
    'Marton Kiss',
    'Istvan Szarka',
    'Andras Gaal',
    'Yevhen Romanov',
    'Ananda Jahn',
    'Namik Karovic',
    'David Guzsik',
    'Jochen Manz',
    'Irene Matthias',
    'Till Brinkmann',
    'Diego Andrade de Mendonca',
    'Yelyzaveta Kucheriavenko',
    'Zsolt Namenyi',
    'Denise Hartmann',
  ].sort();

  return {
    list,
  };
};
