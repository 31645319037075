import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "error-stack-header",
  "data-e2e": "error-stack-header"
}
const _hoisted_2 = {
  class: "error-stack-header__title",
  "data-e2e": "error-stack-title"
}

import ClearIcon from '@/features/ui/icons/ClearIcon.vue';
import AppHeader from '@/features/ui/components/AppHeader.vue';
// FIXME: Is this needed?
import 'vue';
import { $t } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorStackHeader',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppHeader, {
    class: "header",
    "border-bottom": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_unref($t)('pages.error.error-stack.header-title.text')), 1),
        _createElementVNode("div", {
          class: "error-stack-header__close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
        }, [
          _createVNode(ClearIcon, {
            class: "error-stack-header__close--btn",
            "data-e2e": "header-close-button",
            stroke: "#0D3A93",
            fill: "#0D3A93"
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})