
import type { ComponentPublicInstance } from 'vue';
import { defineComponent, onErrorCaptured } from 'vue';
import { useAppServiceResolver } from '../composables';
import { onMounted } from 'vue';

import Error from '@/features/core/errors/Error.vue';
import { measureMemoryUsage } from '@/utils/helpers/measureMemoryUsage';

export default defineComponent({
  name: 'AppServiceResolver',
  components: { Error },
  setup() {
    const { ready, error, instance, info, handleCapturedError } =
      useAppServiceResolver();
    const { performMemoryAuditWithLogs } = measureMemoryUsage();
    onMounted(() => {
      performMemoryAuditWithLogs();
    });

    onErrorCaptured(
      (e: Error, instance: ComponentPublicInstance | null, info: string) => {
        handleCapturedError(e, instance, info);

        return false;
      },
    );

    return {
      ready,
      error,
      instance,
      info,
    };
  },
});
