<template>
  <div>
    <slot name="ready" v-if="ready"
      >{{ $t('pages.app-service-resolver.application-ready.text') }}
    </slot>
    <slot name="error" v-bind="{ error }" v-if="error">
      <Error :error="error" :info="info" :instance="instance" />
    </slot>
    <slot name="loading" v-if="!ready && !error"
      >{{ $t('pages.app-service-resolver.application-loading.text') }}
    </slot>
  </div>
</template>

<script lang="ts">
import type { ComponentPublicInstance } from 'vue';
import { defineComponent, onErrorCaptured } from 'vue';
import { useAppServiceResolver } from '../composables';
import { onMounted } from 'vue';

import Error from '@/features/core/errors/Error.vue';
import { measureMemoryUsage } from '@/utils/helpers/measureMemoryUsage';

export default defineComponent({
  name: 'AppServiceResolver',
  components: { Error },
  setup() {
    const { ready, error, instance, info, handleCapturedError } =
      useAppServiceResolver();
    const { performMemoryAuditWithLogs } = measureMemoryUsage();
    onMounted(() => {
      performMemoryAuditWithLogs();
    });

    onErrorCaptured(
      (e: Error, instance: ComponentPublicInstance | null, info: string) => {
        handleCapturedError(e, instance, info);

        return false;
      },
    );

    return {
      ready,
      error,
      instance,
      info,
    };
  },
});
</script>
