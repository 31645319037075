import type { Product } from '@/features/products/entities';
import type { OrderItem } from '@/features/orders/types';
import type {
  BarcodeLookupResult,
  BarcodeLookupResultBase,
  ConditionalBarcodeLookupResult,
} from '../types';
import { isItemEntity } from './isItemEntity';

export function createBarcodeLookupResult<T extends OrderItem | Product>(
  itemOrProduct: T,
  baseData: BarcodeLookupResultBase,
): ConditionalBarcodeLookupResult<T>;

export function createBarcodeLookupResult(
  itemOrProduct: OrderItem | Product,
  baseData: BarcodeLookupResultBase,
): BarcodeLookupResult {
  if (isItemEntity(itemOrProduct)) {
    return { ...baseData, item: itemOrProduct };
  }

  return { ...baseData, product: itemOrProduct };
}
