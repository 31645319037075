import type { ComponentPublicInstance } from 'vue';
import { computed, ref } from 'vue';
import { resolverServicePlugin } from '@/features/core/resolver';
import { loggerServicePlugin } from '@/features/core/logger';
import type { UseAppServiceResolver } from '../types';
import { APP_CRASH_ERROR_PREFIX } from '../types';

export function useAppServiceResolver(): UseAppServiceResolver {
  const _resolverService = resolverServicePlugin.get();
  const _ready = ref<boolean>(false);
  const _error = ref<null | Error>(null);
  const _info = ref<null | string>(null);
  const _instance = ref<null | ComponentPublicInstance>(null);

  _resolverService.getAllResolvers().then(
    () => (_ready.value = true),
    (reason) => {
      loggerServicePlugin.get().error(reason);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      _error.value = reason;
    },
  );

  const transformError = (e: Error): Error => {
    const transformedError = new Error(APP_CRASH_ERROR_PREFIX + e.message);
    transformedError.name = e.name;
    transformedError.stack = e.stack;
    return transformedError;
  };

  const handleCapturedError = (
    e: Error,
    instance: ComponentPublicInstance | null,
    info: string,
  ) => {
    _ready.value = false;
    _error.value = e;
    _instance.value = instance;
    _info.value = info;

    loggerServicePlugin.get().fatal(transformError(e));

    return false;
  };

  return {
    ready: computed(() => _ready.value),
    error: computed(() => _error.value),
    instance: computed(() => _instance.value),
    info: computed(() => _info.value),
    handleCapturedError,
  };
}
