import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "banner"
}
const _hoisted_2 = { class: "banner__body" }

import Button from '@/features/ui/components/Button.vue';
import ChevronRight from '@/features/ui/icons/ChevronRight.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Banner',
  props: {
  visible: {
    type: Boolean,
    default: true,
    required: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(Button, {
          class: "banner__button",
          tabindex: "-1",
          btnWhite: "",
          icon: "",
          "data-e2e": "banner-go-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createVNode(ChevronRight, { fill: "currentColor" })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})