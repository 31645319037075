//FIXME: Move to separate feature or to utils, this isn't directly related to logger feature
export interface ExecutionTimeStats {
  startTimeMs: number;
  endTimeMs: number;
  durationMs: number;
}

/**
 * Calls the given `resultGetter` function (which can be async), and tracks
 * the initial call time and when the getter finishes executing (promises are
 * "awaited"), then calls the `callback` with execution time statistics.
 */
export const trackExecutionTime = <T>(
  resultGetter: () => T,
  callback: (stats: ExecutionTimeStats) => void,
): T => {
  const startTimeMs = performance.now();
  const handleCompletion = () => {
    const endTimeMs = performance.now();
    callback({
      startTimeMs,
      endTimeMs,
      durationMs: endTimeMs - startTimeMs,
    });
  };
  const result = resultGetter();
  if (result instanceof Promise) {
    void result.then(handleCompletion);
  } else {
    handleCompletion();
  }
  return result;
};
