import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-preview-footer" }

import type { PropType } from 'vue';
import { ref } from 'vue';
// FIXME: This composable is already used by parent component. you props to pass the data down
import { useDeviceInfo } from '@/features/device-id';
// FIXME: Core Feature should not depend on non-core feature
import { reportsServicePlugin } from '@/features/reports';
import Button from '@/features/ui/components/Button.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorPreviewFooter',
  props: {
    error: {}
  },
  setup(__props: any) {

const props = __props;

const { deviceId } = useDeviceInfo();

const isSupportInformed = ref(false);
const isSupportBeingInformed = ref(false);
const informSupport = async () => {
  const report = reportsServicePlugin.get();
  isSupportBeingInformed.value = true;

  isSupportInformed.value = await report.sendErrorReport({
    error: props.error,
    deviceId: deviceId.value,
  });

  location.href = '/';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(Button, {
      disabled: isSupportBeingInformed.value,
      class: "inform-support-button",
      "data-e2e": "go-back-button",
      onClick: informSupport
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('pages.error-page.go-back.text')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}
}

})