// FIXME: Dexie is a Storage implementation, use a Stroage error instead of the dexie error directly
import { Dexie } from 'dexie';
import { cacheReset } from '@/utils/helpers/cacheReset';
// FIXME: Core Feature should not depend on non-core feature
import { ErrorTriggerEvent } from '@/features/sync-scheduler/events';
// FIXME: Core Feature should not depend on non-core feature
import { VerificationDialogShow } from '@/features/login/events';
import { $t } from '@/i18n';
import type { EventBus } from '../event-bus';
import type { NotificationService } from '../notifications';
import { NotificationType } from '../notifications';
import {
  BaseError,
  HandledError,
  isErrorWithMessage,
  UnknownError,
} from './error-classes';
import type { LoggerService } from '@/features/core/logger';

export interface ErrorHandler {
  handle(error: unknown): void;

  setVisibleErrorPopup(visibility: boolean): void;

  registerLoggerService(loggerService: LoggerService): void;
}

export class ErrorHandlerImpl implements ErrorHandler {
  public visibleErrorPopup = false;
  private showExpireTokenPopup = false;
  private loggerService?: LoggerService;

  constructor(
    private notificationService: NotificationService,
    private eventBus: EventBus,
    private windowInstance = globalThis,
  ) {
    this.windowInstance?.addEventListener(
      'unhandledrejection',
      (event: PromiseRejectionEvent) => {
        const error: Error | unknown = event.reason;
        if (
          error instanceof Dexie.DexieError &&
          typeof confirm === 'function' &&
          confirm($t('errors.error-handler.dexie-error.message'))
        ) {
          this.loggerService?.error('DexieError is thrown', error);
          void cacheReset();
        }
      },
    );

    this.registerErrorListener();

    this.eventBus.on(VerificationDialogShow, () => {
      this.showExpireTokenPopup = true;
    });
  }

  setVisibleErrorPopup(visible: boolean): void {
    this.visibleErrorPopup = visible;
  }

  getErrorObject(error: BaseError | unknown): BaseError | null {
    if (!error || error instanceof HandledError) {
      return null;
    }

    if (!(error instanceof BaseError)) {
      const errorReasonMessage = isErrorWithMessage(error)
        ? error.message
        : JSON.stringify(error);

      return new UnknownError({ ErrorReason: errorReasonMessage });
    }

    if (error.handled) {
      return null;
    }

    return error;
  }

  handle(error: BaseError | HandledError | unknown): void {
    const errorObject = this.getErrorObject(error);
    if (errorObject === null) {
      return;
    }

    if (this.showExpireTokenPopup || this.visibleErrorPopup) {
      return;
    }

    this.notifyAboutError(errorObject);
  }

  public registerLoggerService(loggerService: LoggerService): void {
    this.loggerService = loggerService;
  }

  private notifyAboutError(error: BaseError): void {
    this.eventBus.emit(new ErrorTriggerEvent(error.message));
    this.loggerService?.log(error.getLogLevel(), error);
    if (error.originalError && error.originalError instanceof Error) {
      this.loggerService?.log(error.getLogLevel(), error.originalError);
    }
  }

  private registerErrorListener() {
    this.eventBus.on(ErrorTriggerEvent, (event) => {
      this.notificationService.show({
        text: event.error,
        type: NotificationType.Error,
      });
    });
  }
}
